<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      Please choose the name of your organization to proceed to the login page:
    </p>
    <ul>
      <li v-for="org in orgs" :key="org.name">
        <a :href="org.url">{{ org.name }}</a>
      </li>
    </ul>
    <p>
      To add your organization to this list, please contact <a href="mailto:support@safetelecom.net">support@safetelecom.net</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Portal',
  props: {
    msg: String
  },
  data () {
    return {
      orgs: [
        {
          name: "Bnos Bais Yaakov",
          url: "https://bbyfr.admirepro.org/"
        },
        {
          name: "Mesivta of Long Beach",
          url: "https://mlb.admirepro.org/"
        },
        {
          name: "Tomchei Shabbos of Lakewood",
          url: "https://tsl.admirepro.org/"
        },
        {
          name: "Yeshiva Gedolah Ohr Chodosh",
          url: "https://ygoc.admirepro.org/"
        },
        {
          name: "Yeshiva Toras Aron",
          url: "https://torasaron.admirepro.org/"
        },
        {
          name: "Adrenaline Demo",
          url: "https://adrenalinedemo.admirepro.org/School?ClassroomMode=True"
        },
        {
          name: "Admire Staging",
          url: "https://admireedustaging.admirepro.org/"
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
